<template>
  <!-- confirm START -->
  <transition name="fade" mode="out-in">
    <div class="dialog dialog-confirm" v-if="loadingVisible">
      <div class="dialog-confirm-container">

        <div class="inner">
          <div class="icon read" v-if="type === 'read'"></div>
          <div class="icon unread" v-if="type === 'unread'"></div>
          <div class="icon delete" v-if="type === 'delete'"></div>
          <h4 v-html="headline"></h4>
          <div class="excerpt" v-html="excerpt"></div>

          <div class="dialog-footer">
            <div
              class="btn-inverted close"
              v-on:click="close()"
              v-html="$t('dialog.back')"
            ></div>
            <div
              class="btn-default confirm"
              v-on:click="confirm(type)"
              v-html="$t('dialog.confirm')"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <!-- confirm END -->
</template>

<script>
export default {
  name: 'dialog-confirm',
  props: {
    loadingVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
    },

    excerpt: {
      type: String,
    },

    open: {
      type: Function,
    },

    close: {
      type: Function,
    },

    confirm: {
      type: Function,
    },

    type: {
      type: String,
    },
  },
};
</script>
